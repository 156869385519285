<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="330" min-height="330" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="top" :rules="rules">
          <el-form-item prop="name" label="产品名称">
            <el-input v-model="data.name" placeholder="输入产品名称" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="productTypeId" label="产品类型">
            <el-select filterable default-first-option v-model="data.productTypeId" placeholder="选择产品类型"
              style="width: 100%" @change="changeProduct()">
              <el-option :label="item.name" :value="item.id" v-for="(item, key) in productType" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        name: [{ required: true, trigger: 'blur', message: '输入产品名称' }],
        productTypeId: [{ required: true, trigger: 'change', message: '选择产品类型' }],
      },
      loading: false,
      isDisable: true,
      productType: []
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = this.$utils.clone(data.data, true)
      this.productType = data.productType
      this.name = data.name
    },
    changeProduct() {
      this.productType.forEach(item => {
        if (item.id == this.data.productTypeId) this.data.productTypeName = item.name
      })
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = this.data
          this.$axios
            .post('/order/product', params)
            .then((res) => {
              if (res) {
                console.log(this.$utils.isUndefined(this.data.id))
                if (this.$utils.isUndefined(this.data.id)){
                  this.$message.success('新增成功')
                }
                else {
                  this.$message.success('编辑成功')
                }
                this.show = false
                this.$emit('init')
              }
              this.loading = false
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>